import React from "react";
import "./index.css";

export default function Header(props) {
  return (
    <div className="header">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 505.23 137.35"
        className="logo"
      >
        <path d="M224.51,64.35c-1.08-4-6.55-4.4-9.07.46s-4.55,21.82-6.36,23.62-5.26-5.25-9.24-10.58c-6.51-8.69-11.64-19.62-15.35-15.15-5.86,7.08-12.62,22.41-12.62,22.41A103.15,103.15,0,0,0,167.73,69c-1.33-3.6-3.13-6.34-8.31-7-11.92-1.6-20.29-2-34,1.06-3.81.85,15.46,8,10.84,25.46-2.7,10.17-16.33,13.42-24.31,6.65-8.28-7-8.83-19.22.36-23.94,6.92-3.55,13.29-8.5,8-9.69-9.8-2.17-18.6.59-23.07,7.94-8.91,14.69,1.54,28.5,1.54,28.5-3.25,1.65-9.34.35-11.81-.27-8.21-2-8.6-10.91-8.6-10.91A178.89,178.89,0,0,0,84.8,55C87.48,27.71,82.06,8.31,74,8.37,62,8.45,57,24.49,57,53.74a161.12,161.12,0,0,0,3.5,31.49s-7.78,10-12.73,14.52l-2.64,2.84s-1.48,4.74,4.76,6S69.84,94,69.84,94s4,12.46,18.86,13.8c13.67,1.24,17.78-1.9,17.78-1.9,5.58,4.14,18.22,7,30-.09a23.31,23.31,0,0,0,11-17.81c1.5-14.46-9.83-20.51-6.85-20.76,11.71-1,16.69-1.8,21.72,25,1.78,9.58,9,13.67,11.78,9,4.11-7,9.29-16.21,11.63-18.91s6.71,7.42,16.21,18.18c8.08,9.16,12.73,6.76,16-6.26C219,90.52,225.59,68.31,224.51,64.35ZM74.46,53.9c-1,10-2.15,30.9-5.31,22.43-4-10.56-4.32-58.63,3.79-51.84C74.8,26,76.27,36.5,74.46,53.9Z" />
        <path d="M230.63,64.31c4-4.46,7.92-6.26,8.29-2.3a22.67,22.67,0,0,1,0,5.76s5.7-5.61,11.36-8.54c7.25-3.75,14.5-1.62,19.77,13.57,5.64,16.28,7.46,18.06,20.58,17.09s11.42,15.16-3.49,14.21S267,97.64,263.21,85c-5.62-18.87-12.18-15.58-16.08-12-7.88,7.26-3.33,19.81-8.51,22.7s-8.88,0-8.52-9.72S230.63,64.31,230.63,64.31Z" />
        <path d="M334.59,90.51c-3.76-9.45-1.4-20.24,5.53-15.87,3.58,2.25,4.94,8.86,7.41,14.41,1.64,3.71,2.25-15,4.11-16.53s11.88,16,14.85,21.17,5.21,7.54,8.91-5.52,6.69-21.62,15.23-21.61,7.8,4.85,20.06,4.48,14.49,1.68,13.75,5.85S415.2,81,409.63,86.55s-9.71,14.67-1.91,9.79,6.69-7.92,13-12,6.31,0,6.31,6.14,11.89,10.61,19.33,2.07,2.22-19.32,10.76-23.4,11.14,2.43,8.92,7.71-8.55,15-3.72,16.8,11.89-2.68,19.32-9.32,21.19-9.75,23.4,13.46c1.52,16-6.49,31.9-18.27,37.35-17.76,8.21-39.81-9.64-25.5-7.76,12.9,1.69,18.84,2.51,24.16-3.23,15.8-17,12.09-34.82-1.34-27.85-5.75,3-23.2,17.77-27.94,12.43s-5.13-10.27-13.34-3.9-18.3,9.45-22.4,2.06-5.55-2.88-13.36.2-16.23,1.85-16.85-6.78,12.62-22.17,12.62-22.17S394.52,73,390,79.36s-10.33,25.17-11.81,30.74-11.76,7.39-15.73-.5c-6.41-12.73-10.28-14.38-10.9-9.25s-3.28,23.91-8,14.11S334.59,90.51,334.59,90.51Z" />
        <path d="M324.66,84.37c-4.08-7.39-10-7.39-10-7.39-8.17-1.11-21.23,8.57-24.67,19.89-3.83,12.57,3.62,18,16.25,12s14-10.46,17.54-3.2c4.94,10.16,11.86,10.37,12.8,6.94C338.51,105.44,328.75,91.76,324.66,84.37Zm-22.85,16.14c-4.34-1.91-.64-8.21,4.92-11.26,4.46-2.43,8.48-2.17,9.24,1.26C317,95.06,307,102.77,301.81,100.51Z" />
        <path d="M51,62.27c-11.4-17-39.42,5-39.42,5V9.17c0-6.27-1-12.88-7.16-6.69C-.2,7.13,0,9.25,0,9.25V94C0,106.71,16.74,109.35,25.6,107,37,104.07,62.44,79.29,51,62.27ZM40.48,80.15S35.8,88.47,28.87,92.8c-6.45,4-15.92,4.66-15.53-3.12a23.47,23.47,0,0,1,4.28-12.62c10.55-12.16,16.4-10.49,21-8C42.08,71,42.18,75.82,40.48,80.15Z" />
      </svg>
    </div>
  );
}
