import React from "react";
import "./App.css";
import Header from "../src/components/Header/index";
import Banner from "../src/components/Banner/index";
import CardContainer from "../src/components/CardContainer";
import Footer from "../src/components/Footer";

function App() {
  return (
    <div className="App">
      <div className="body">
        <Header></Header>
        <Banner></Banner>
        <CardContainer></CardContainer>
        <Footer></Footer>
      </div>
    </div>
  );
}

export default App;
