import React from "react";
import "./index.css";

export default function Banner() {
  return (
    <a
      href="https://www.coursesbytrum.se/p/blown-away"
      target="_blank"
      rel="noopener noreferrer"
      className="banner"
    >
      <img
        className="banner-logo"
        src="https://res.cloudinary.com/carlhernek/image/upload/f_auto,q_auto,w_512/v1591857280/Blownaway/business_bild_v4cito.png"
        alt="logo"
      />
      <h1>
        Utmana din affärsmodell, <br /> omvandla och förändra nu!{" "}
      </h1>
      <img
        className="banner-img"
        src="https://res.cloudinary.com/carlhernek/image/upload/f_auto,q_auto,w_512/v1591857281/Blownaway/header_courses_bakgrund_fmyyie.png"
        alt="background-texture"
      />
    </a>
  );
}
